import React, { memo, useState } from 'react';
import { Modal, Table, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { LinkOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store';
import { actionPutHotel, actionResetListHotelMapped, selectListHotelMapped } from 'store/metaDataHotelSlice';
import { SUPPLIERS } from 'constants/hotel';
import { getHotelBaseUrl } from 'util/hotel';
import moment from 'moment';

interface IMetaDataHotel {
  hotelId: string;
  name: string;
  identifier: string;
  supplierId: string;
  link: string;
}

interface Iprops{
    openModal:boolean;
    setOpenModal: any; 
    infoMappingHotel:any
}
const titleFooter = '기존에 매핑된 호텔입니다. 변경하시겠습니까?'
const columns: ColumnsType<IMetaDataHotel> = [
  {
    title: 'hotel ID',
    width: '8%',
    dataIndex: 'hotelId',
    align: 'center',
    key: 'hotelId',
  },
  {
    title: 'hotel name',
    width: '30%',
    dataIndex: 'name',
    align: 'center',
    key: 'name',
  },
  {
    title: 'identifier',
    width: '26%',
    dataIndex: 'identifier',
    align: 'center',
    key: 'identifier',
  },
  {
    title: 'supplier',
    width: '14%',
    dataIndex: 'supplierId',
    align: 'center',
    key: 'supplier',
  },
  {
    title: 'Created At',
    width: '14%',
    dataIndex: 'createdAt',
    align: 'center',
    key: 'supplier',
    render: (text:string) => moment(text).format('YYYY-MM-DD')
  },
  {
    title: 'link',
    width: '8%',
    dataIndex: 'link',
    align: 'center',
    key: 'link',
    render: (link: string, record: IMetaDataHotel) => (
      <Button
        type="link"
        className="w-full h-full hover:bg-[#93c5fd] hover:text-white"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <a
          href={
            +record.supplierId !== SUPPLIERS.naver.id
              ? `${getHotelBaseUrl(+record.supplierId)}${link}`
              : `${getHotelBaseUrl(+record.supplierId)}${link.replace('item/photo', 'item')}`
          }
          target="_blank"
          rel="noreferrer"
          key={`${link}${record.identifier}`}
        >
          <LinkOutlined className="text-[14px]" />
        </a>
      </Button>
    ),
  },
];

function ModalMapping(props:Iprops) {
    const {openModal, setOpenModal, infoMappingHotel} = props
    const listHotelMapped = useAppSelector(selectListHotelMapped)
    const dispatch = useAppDispatch();

  const handleResetModalData = () => {
    dispatch(actionResetListHotelMapped)
  }
  const handleOk = () => {
    console.log('ok');
    
    handleResetModalData()
    setOpenModal(false);
    const itemExisted:any = listHotelMapped.find((item:any) => item.supplierId === infoMappingHotel.supplierId)
    if(itemExisted){
      dispatch(
        actionPutHotel({
          data: {
            id: infoMappingHotel.id,
            hotelId: itemExisted.hotelId,
            status: 1,
          },
        })
      );
      dispatch(
        actionPutHotel({
          data: {
            id: itemExisted.id,
            hotelId: null,
            status: 2,
          },
        })
      );
    }

  };

  const handleCancel = () => {
    handleResetModalData()
    setOpenModal(false);
  };

  return (
      <Modal
        title={titleFooter}
        visible={openModal}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1200} // Adjust width as needed
      >
        <Table
          columns={columns}
          dataSource={listHotelMapped.filter((item:any) => item.supplierId === infoMappingHotel.supplierId)}
          pagination={false}
          rowKey={(record) => record.hotelId}
        />
      </Modal>
  );
};

export default memo(ModalMapping);
