export const SUPPLIER_NAME = [
  {
    id: 7,
    show: true,
    name: 'privia',
    domain: 'hotel.priviatravel.com/',
  },
  {
    id: 8,
    show: true,
    name: 'tourvis',
    domain: 'hotel.tourvis.com',
  },
  {
    id: 12,
    show: true,
    name: 'kyte',
    domain: 'kyte.travel',
  },
  {
    id: 1,
    show: true,
    name: 'expedia',
    domain: 'expedia.com/',
  },
  {
    id: 2,
    show: true,
    name: 'agoda',
    domain: 'agoda.com/',
  },
  {
    id: 3,
    show: true,
    name: 'booking',
    domain: 'booking.com/',
  },
  {
    id: 4,
    show: true,
    name: 'trip',
    domain: 'trip.com/hotels/',
  },
  {
    id: 5,
    show: true,
    name: 'naver',
    domain: 'hotels.naver.com/',
  },
  {
    id: 6,
    show: true,
    name: 'hotels',
    domain: 'hotels.com/',
  },
];

export const SUB_SUPPLIER_NAME = {
  dc: {
    id: 1,
    name: 'dc',
  },
  cms: {
    id: 2,
    name: 'cms',
  },
  supplier: {
    id: 3,
    name: 'supplier',
  },
  discountDetail: {
    id: 10001,
    name: 'detailDiscount',
  },
  detail: {
    id: 10002,
    name: 'detail',
  },
};

export const STATUS_MAPPED = [
  {
    name: 'NEW',
    value: 0,
  },
  {
    name: 'NO MAP',
    value: 2,
  },
  {
    name: 'MAPPED',
    value: 1,
  },
];

export const SUPPLIERS = {
  expedia: {
    id: 1,
    name: 'expedia',
  },
  agoda: {
    id: 2,
    name: 'agoda',
  },
  booking: {
    id: 3,
    name: 'booking',
  },
  trip: {
    id: 4,
    name: 'trip',
  },
  naver: {
    id: 5,
    name: 'naver',
  },
  hotels: {
    id: 6,
    name: 'hotels',
  },
  privia: {
    id: 7,
    name: 'privia',
  },
  tourvis: {
    id: 8,
    name: 'tourvis',
  },
  kyte: {
    id: 12,
    name: 'kyte',
  },
};

export const INTERNAL_SUPPLIERS_IDS = [7, 8, 12];
