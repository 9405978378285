interface props {
  txt: string;
}
function RenderDiff({ txt }: props) {
  if (txt === 'NaN/NaN' || txt === undefined) {
    return <div>-</div>
  }
  const diffPrice = parseInt(txt.split('/')[0], 10);
  const diffPercentage = txt.split('/')[1];
  const signNumber = diffPrice > 0 ? 'text-red-500' : 'text-blue-500';
  return (
    <div className="flex flex-col gap-1 m-1">
      <span className={signNumber}>{diffPrice.toLocaleString()}</span>
      <span className={signNumber}>{diffPercentage}%</span>
    </div>
  );
}
export default RenderDiff;
