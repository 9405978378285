import { SUB_SUPPLIER_NAME, SUPPLIER_NAME, SUPPLIERS } from 'constants/hotel';
import _ from 'lodash';
import { IMetaDataHotel } from 'app/pages/HotelResult';

export const getSupplierName = (hotelId: number) => {
  const result = _.find(SUPPLIER_NAME, { id: hotelId });
  return result?.name || undefined;
};

export const getSubSupplierName = (supplierId: number) => {
  const result = _.find(SUB_SUPPLIER_NAME, { id: supplierId });
  return result?.name || undefined;
};

export const getHotelBaseUrl = (hotelId: number) => {
  const result = _.find(SUPPLIER_NAME, { id: hotelId });

  return `https://${result?.domain}` || undefined;
};

export const renderCheckEmpty = (txt: string) => (txt ? txt.toLocaleString() : '-');

export const renderCheckYorN = (txt: String | unknown | null) => (txt === 'y' ? 'Y' : 'N');

export const getLowestPrice = (record: any) => {
  let lowestPrice = Infinity;
  SUPPLIER_NAME.map((item) => `price${item.name}`);
  Object.keys(record).forEach((key) => {
    if (key.includes('price')) {
      if (record[key] < lowestPrice && record[key] > 0) {
        lowestPrice = record[key];
      }
    }
  });
  return lowestPrice;
};

const getDiffPrice = (supplierPrice: string, dcPrice: string, cmsPrice: string, naverprice: string) => {
  const parsedNaver = parseInt(naverprice, 10);
  const parsedSupplierPrice = parseInt(supplierPrice, 10);
  const parsedDCPrice = parseInt(dcPrice, 10);
  const parsedCMSPrice = parseInt(cmsPrice, 10);
  const diffSupPrice = parsedNaver - parsedSupplierPrice;
  const diffSupPercentage = (+(diffSupPrice / parsedSupplierPrice) * 100).toFixed(2);
  const diffDCPrice = parsedNaver - parsedDCPrice;
  const diffDCPercentage = (+(diffDCPrice / parsedDCPrice) * 100).toFixed(2);
  const diffCMSPrice = parsedNaver - parsedCMSPrice;
  const diffCMSPercentage = (+(diffCMSPrice / parsedCMSPrice) * 100).toFixed(2);
  return {
    diffSup: `${diffSupPrice}/${diffSupPercentage}`,
    diffDC: `${diffDCPrice}/${diffDCPercentage}`,
    diffCMS: `${diffCMSPrice}/${diffCMSPercentage}`,
  };
};

export const getPriceAndDiff = (supplierName: string, inputData: IMetaDataHotel[]) =>
  inputData.map((item) => {
    const diffPrice = getDiffPrice(
      item[`pricesupplier${supplierName}`],
      item[`pricedc${supplierName}`],
      item[`pricecms${supplierName}`],
      item[`price${SUPPLIERS.naver.name}`]
    );
    return {
      ...item,
      ...diffPrice,
    };
  });
export const convertDayType = (dayType: string, subsequentWeek: number) => {
  const targetDayInWeek = dayType === 'weekday' ? 1 : 6;
  const date = new Date();
  date.setDate(date.getDate() + (targetDayInWeek - (date.getDay() % 7)) + 7 * subsequentWeek);
  let month: string | number = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};
