import {getLowestPrice} from "util/hotel";

export const renderDiff = (txt: string) => {
  if (txt === 'NaN/NaN' || txt === undefined) {
    return '-';
  }
  const diffPrice = parseInt(txt.split('/')[0], 10);
  const diffPercentage = txt.split('/')[1];
  const signNumber = diffPrice > 0 ?  'text-red-500' : 'text-blue-500'
  return (
    <div className="flex flex-col gap-1 m-1">
      <span className={signNumber}>{diffPrice.toLocaleString()}</span>
      <span className={signNumber}>{diffPercentage}%</span>
    </div>
  );
};

export const renderCheckLowestPrice: any = (txt: number, record: any) => {
  if (!txt) return '-';
  if (getLowestPrice(record) === txt && txt !== 0) {
    return <div className="text-red-500">{txt.toLocaleString()}</div>;
  }
  return txt.toLocaleString();
};
