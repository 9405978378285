import { getHotelBaseUrl } from 'util/hotel';
import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';

export interface LinkProps{
  link: string;
  supplierId: number;
  identifier: string;

}
function LinkClickButton({ link, supplierId, identifier }:LinkProps) {
  return (
    <Button
      type="link"
      className="w-full h-full hover:bg-[#93c5fd] hover:text-white"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <a
        href={`${getHotelBaseUrl(supplierId)}${link}`}
        target="_blank"
        rel="noreferrer"
        key={`${link}${identifier}`}
      >
        <LinkOutlined className="text-[14px]" />
      </a>
    </Button>
  );
}
export default LinkClickButton;
