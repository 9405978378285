import { SUPPLIER_NAME } from 'constants/hotel';
import { Checkbox, Switch } from 'antd';
import { useEffect } from 'react';
import { cloneDeep, keys } from 'lodash';

interface GridGroupProps {
  setListSupplierName: any;
  showGrid: boolean;
  listSupplierName: any;
  check: any;
  setCheck: any;
}
const defaultSupplierName = cloneDeep(SUPPLIER_NAME);
function SupplierModeGroup({ listSupplierName, setListSupplierName, showGrid, check, setCheck }: GridGroupProps) {
  const handleChangeSupplierShow = (name: string) => {
    const newListSupplier = listSupplierName.map((item: any) => {
      if (item.name === name) {
        // eslint-disable-next-line no-param-reassign
        item.show = !item.show;
      }
      return item;
    });
    setListSupplierName(newListSupplier);
  };

  const onChange = (checked: boolean, button: any) => {
    if (button.target.id === 'privia') {
      setCheck({ privia: !check.privia, tourvis: false, kyte: false });
    }
    if (button.target.id === 'tourvis') {
      setCheck({ privia: false, tourvis: !check.tourvis, kyte: false });
    }
    if (button.target.id === 'kyte') {
      setCheck({ privia: false, tourvis: false, kyte: !check.kyte });
    }
  };

  useEffect(() => {
    if (check.privia) {
      setListSupplierName(SUPPLIER_NAME.filter((item) => ['privia', 'naver'].includes(item.name)));
    } else if (check.tourvis) {
      setListSupplierName(SUPPLIER_NAME.filter((item) => ['tourvis', 'naver'].includes(item.name)));
    } else if (check.kyte) {
      setListSupplierName(SUPPLIER_NAME.filter((item) => ['kyte', 'naver'].includes(item.name)));
    } else setListSupplierName(defaultSupplierName);
  }, [check, setListSupplierName]);

  return showGrid ? (
    <div className="h-[50px] w-[840px] flex justify-center mb-1">
      {listSupplierName.map((item: any, index: number) => (
        <div className="w-[50px] inline-flex mr-3 justify-center items-center" key={index}>
          <div className="flex flex-col ">
            <div className="flex justify-center items-center ">
              <Checkbox className="ml-1" checked={item.show} onChange={() => handleChangeSupplierShow(item.name)} />
            </div>
            <span>{item.name.toUpperCase()}</span>
          </div>
        </div>
      ))}
      <div className="flex items-center gap-1">
        <div>
          <Switch
            size="small"
            id="privia"
            checked={check.privia}
            onChange={(checked, button) => onChange(checked, button)}
          />
          <span> Privia </span>
        </div>
        <div>
          <Switch
            size="small"
            id="tourvis"
            checked={check.tourvis}
            onChange={(checked, button) => onChange(checked, button)}
          />
          <span className='w-[140px]'> Tourvis</span>
        </div>
        <div>
          <Switch

            size="small"
            id="kyte"
            checked={check.kyte}
            onChange={(checked, button) => onChange(checked, button)}
          />
          <span className="pr-[4px]"> Kyte </span>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
export default SupplierModeGroup;
