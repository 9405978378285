import request from 'util/request';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { get } from 'lodash';

interface IParamsGetHotelResult {
  keywordId: number | undefined;
  checkIn: string;
}

interface IHotelResult {
  loading: boolean;
  data: any;
  currentSupplierId: number;
  keywords: any[];
  supplierInfo: any;
}

interface IBodyGenerateLink {
  keywordId: number;
  checkinDate: string;
  supplierId: number;
  createdAt: string;
}

const initialState: IHotelResult = {
  loading: false,
  data: [],
  currentSupplierId: 1,
  keywords: [],
  supplierInfo: [],
};
export const actionHotelResult = createAsyncThunk(
  'hotel/actionGetHotelResult',
  async (params: IParamsGetHotelResult, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/hotel-data/latest-data',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const actionGetKeyword = createAsyncThunk('hotel/actionGetKeyword', async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/keyword',
      method: 'GET',
    });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const actionGetHotelInfo = createAsyncThunk('hotel/actionHotelInfo', async (params, { rejectWithValue }) => {
  try {
    const response = await request({
      url: '/supplier/list',
      method: 'GET',
    });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const actionGenerateLink = createAsyncThunk(
  'hotel/actionGenerateLink',
  async (data: IBodyGenerateLink, { rejectWithValue }) => {
    try {
      const response = await request({
        url: '/hotel/generate-link',
        method: 'POST',
        data,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const slice = createSlice({
  name: 'hotelResultSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actionHotelResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(actionHotelResult.fulfilled, (state, action) => {
        state.loading = false;
        state.data = get(action, 'payload.data');
      })
      .addCase(actionGetKeyword.fulfilled, (state, action) => {
        state.keywords = get(action, 'payload.data');
      })
      .addCase(actionGetKeyword.rejected, (state) => {
        state.keywords = [];
      })
      .addCase(actionGetHotelInfo.fulfilled, (state, action) => {
        state.supplierInfo = action.payload.data;
      });
  },
});

export const selectHotelResult = (state: RootState) => state.hotelResultSlice;
export const selectDataHotelResult = (state: RootState) => state.hotelResultSlice.data;
export const selectLoadingHotelResult = (state: RootState) => state.hotelResultSlice.loading;
export const selectKeyword = (state: RootState) => state.hotelResultSlice.keywords;

export default slice.reducer;
