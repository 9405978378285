import { INTERNAL_SUPPLIERS_IDS, SUPPLIER_NAME, SUPPLIERS } from 'constants/hotel';
import { convertDayType, getPriceAndDiff, getSubSupplierName, getSupplierName, renderCheckEmpty } from 'util/hotel';
import exportTableToExcelFile from 'util/xlxs';
import {renderCheckLowestPrice} from "util/hotelResult";
import {MAX_RANK_WITH_DETAIL_PRICE} from "constants/app";
import { Button, Modal, notification, Popconfirm, Segmented, Select, Switch, Table } from 'antd';
import {
  actionGenerateLink,
  actionGetKeyword,
  actionHotelResult,
  selectDataHotelResult,
  selectKeyword,
  selectLoadingHotelResult,
} from 'store/hotelResultSlice';
import SupplierModeGroup from 'app/components/SupplierModeGroup';
import { useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import _, { cloneDeep } from 'lodash';
import type { ColumnsType } from 'antd/es/table';
import WrapperLayoutContent from 'app/components/WrapperLayoutContent';
import { EllipsisOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  actionChangeCache,
  actionGetListHotelRecommend,
  actionPutHotel,
  IHotelDataMapping,
} from 'store/metaDataHotelSlice';
import { actionGetHotelInSupplierByName, selectMappingResult } from 'store/hotelMappingResultSlice';
import HighlightText from 'app/components/HighlightText';
import TableMapping from 'app/pages/MetaData/TableMapping';
import {
  actionGetHotelIsMapped,
  actionSetHotelIDIsMapped,
  selectHotelIsMapped,
  selectHotelLinkByKeyword,
} from 'store/hotelSlice';
import moment from 'moment';
import LinkClickButton from 'app/components/LinkClickButton';
import childrenColumnsOfSuppliers from 'app/pages/HotelResult/constants';

export interface IMetaDataHotel {
  [key: string]: string;
}
export interface IHotelInSupplierByName {
  id: number;
  supplierId: number;
  name: string;
  identifier: string;
  link: string;
  key: number;
}
function HotelResult() {
  const dispatch = useAppDispatch();
  const [listSupplierName, setListSupplierName] = useState(cloneDeep(SUPPLIER_NAME));
  const [showGrid, setShowGrid] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState(0);
  const [supplierCurrent, setSupplierCurrent] = useState<number>(SUPPLIERS.privia.id);
  const [keywordId, setKeywordId] = useState<number>(1);
  const selectedHotelDetail: IHotelDataMapping[] = useAppSelector(selectHotelIsMapped);
  const [noMapHotelDetail, setNoMapHotelDetail] = useState<any>([]);
  const data = useAppSelector(selectDataHotelResult);
  const loading = useAppSelector(selectLoadingHotelResult);
  const keywords = useAppSelector(selectKeyword);
  const linkHotelByKeyword = useAppSelector(selectHotelLinkByKeyword);
  const [isClickNoneHotelID, setIsClickNoneHotelID] = useState(false);
  const [dataSource, setDataSource] = useState<IMetaDataHotel[]>();
  const [check, setCheck] = useState({
    privia: false,
    tourvis: false,
    kyte: false,
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const weekOptions = [
    { value: '3weekday', label: '평일 (3 다음 주)' },
    { value: '3weekend', label: '주말 (3 다음 주)' },
    { value: '5weekday', label: '평일 (5 다음 주)' },
    { value: '5weekend', label: '주말 (5 다음 주)' },
  ];
  const [selectedDay, setSelectedDay] = useState('3weekday');
  const [targetCheckin, setTargetCheckin] = useState(
    convertDayType(selectedDay.slice(1), parseInt(selectedDay.slice(0, 1), 10))
  );
  const today = moment().format('MM_DD');
  const nextCheckin = moment(targetCheckin, 'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD');

  const exportExcel = (datasource: any) => {
    if (datasource) {
      const fileName = `${today}_${keywords.find((item) => item.id === keywordId).keyword}_${
        SUPPLIER_NAME.find((item) => item.id === supplierCurrent)?.name
      }_${moment(targetCheckin).format('MM_DD')}.xlsx`;
      exportTableToExcelFile('dataHotelResult', fileName);
    }
  };

  const { modalData } = useAppSelector(selectMappingResult);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const handleSelectSupplierName = (id: number) => {
    setSupplierCurrent(id);
  };

  const handleChange = (value: any) => {
    setKeywordId(value);
  };

  const handleChangeDayType = (e: string) => {
    setSelectedDay(e);
  };

  const onReloadData = () => {
    if (data.supplierData[0]) {
      const createdAt = `${new Date(data.supplierData[0].createdAt).toISOString().split('.')[0]}Z`;
      const dayOfWeeks = selectedDay.slice(1) === 'weekday' ? 1 : 6;
      const subsequentWeek = selectedDay.slice(0, 1);
      const body = {
        keywordId,
        checkinDate: targetCheckin,
        supplierId: supplierCurrent,
        createdAt,
        dayOfWeeks,
        subsequentWeek,
      };
      dispatch(actionGenerateLink(body));
    } else {
      notification.error({ message: '데이터 처리 중입니다. 잠시 후 다시 시도해주세요.' });
    }
  };

  const selectedHotelColumns: ColumnsType<IHotelDataMapping> = [
    {
      title: 'Hotel Id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      align: 'center',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      align: 'center',
    },
  ];

  const selectedNoneHotelID: ColumnsType<IHotelDataMapping> = [
    {
      title: 'Id',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierId',
      align: 'center',
      render: getSupplierName,
    },
    {
      title: 'Identifier',
      dataIndex: 'identifier',
      align: 'center',
    },
    {
      title: 'Link',
      dataIndex: 'link',
      align: 'center',
      render: (__, record) => (
        <LinkClickButton link={record.link} supplierId={record.supplierId} identifier={record.identifier} />
      ),
    },
  ];

  const modalColumns: ColumnsType<IHotelInSupplierByName> = [
    {
      title: 'Id',
      align: 'center',
      dataIndex: 'id',
    },
    {
      title: 'SupplierId',
      align: 'center',
      dataIndex: 'supplierId',
      render: getSupplierName,
    },
    {
      title: 'Name',
      align: 'center',
      dataIndex: 'name',
      render: (txt: string) => (
        <div className="!text-left px-[12px]">
          <HighlightText txt={txt} searchString={selectedHotelDetail[0]?.name} />
        </div>
      ),
    },
    {
      title: 'Identifier',
      align: 'center',
      dataIndex: 'identifier',
    },
    {
      title: 'Link',
      align: 'center',
      dataIndex: 'link',
      render: (__, record) => (
        <LinkClickButton link={record.link} supplierId={record.supplierId} identifier={record.identifier} />
      ),
    },
    {
      title: 'Action',
      align: 'center',
      render: (record) => (
        <Button
          className="uppercase w-full h-full hover:bg-[#93c5fd] hover:text-white font-semibold"
          type="link"
          onClick={() => {
            handleCloseModal();
            dispatch(
              actionPutHotel({
                data: {
                  hotelId: selectedHotelId,
                  id: record.id,
                  status: 1,
                },
              })
            );
            dispatch(actionHotelResult({ keywordId, checkIn: targetCheckin }));
          }}
        >
          map
        </Button>
      ),
    },
  ];

  const childrenAPIPriceColumn = (loginState: any, name: any) =>
    loginState
      ? {
          title: 'API 가격',
          dataIndex: `priceloginapi${name}`,
          align: 'center',
          width: '6%',
          render: renderCheckLowestPrice,
        }
      : {
          title: '가격',
          dataIndex: `price${name}`,
          align: 'center',
          width: '6%',
          render: renderCheckLowestPrice,
        };

  const columns: ColumnsType<IMetaDataHotel> = useMemo(() => {
    const cols: any = listSupplierName.map((item) => {
      const { id, name, show } = item;
      let childrenSites = [];
      if (INTERNAL_SUPPLIERS_IDS.includes(id)) childrenSites = childrenColumnsOfSuppliers[name];

      const childrenSitesInCheckPriceDiffMode =
        check.privia || check.tourvis || check.kyte ? childrenSites : childrenSites.filter((child) => child.key);
      return (
        (show ? true : null) && {
          title: (
            <>
              <a href={linkHotelByKeyword[`${id}`]} target="_blank" rel="noreferrer" style={{ color: 'black' }}>
                {` ${name} `}
              </a>
              {/* {id === SUPPLIERS.privia.id && <Switch size="small" onChange={() => setIsLoggedIn(!isLoggedIn)} />} */}
            </>
          ),
          key: id,
          children: [
            {
              title: 'rank',
              dataIndex: isLoggedIn && id === SUPPLIERS.privia.id ? `ranklogin${name}` : `rank${name}`,
              align: 'center',
              width: '6%',
              onCell: (record: any) => ({
                onClick: () => {
                  if (id !== SUPPLIERS.tourvis.id && id !== SUPPLIERS.privia.id) {
                    setSelectedHotelId(record.hotelId);
                    const rankTarget = `rank${name}`;
                    if (record[rankTarget] === undefined && record.hotelId > 0) {
                      setIsOpenModal(true);
                      dispatch(actionGetHotelInSupplierByName({ supplierId: id, name: record.name }));
                    }
                  }
                },
              }),
              render: renderCheckEmpty,
            },
            id === SUPPLIERS.privia.id
              ? {}
              : {
                  title: '대표가격',
                  dataIndex: `price${name}`,
                  align: 'center',
                  width: '6%',
                  render: renderCheckLowestPrice,
                  onCell: (record: any) => ({
                    onClick: () => {
                      if (id !== SUPPLIERS.tourvis.id) {
                        setSelectedHotelId(record.hotelId);
                        const rankTarget = `rank${name}`;
                        if (record[rankTarget] === undefined && record.hotelId > 0) {
                          setIsOpenModal(true);
                          dispatch(actionGetHotelInSupplierByName({ supplierId: id, name: record.name }));
                        }
                      }
                    },
                  }),
                },
            ...childrenSitesInCheckPriceDiffMode,
          ],
        }
      );
    });

    return cols.reduce((total: any[], currentItem: any) => {
      if (currentItem && currentItem.key === supplierCurrent) {
        const updatedChildren = [
          {
            title: 'Hotel ID',
            dataIndex: 'hotelId',
            align: 'center',
            width: '4%',
            render: (txt: string) => txt || '-',
            onCell: (record: any) => ({
              onClick: () => {
                if (record.hotelId === null) {
                  setNoMapHotelDetail([record]);
                  setIsOpenModal(true);
                  setIsClickNoneHotelID(true);
                  dispatch(
                    actionGetListHotelRecommend({
                      hotelName: record.name,
                    })
                  );
                  dispatch(actionChangeCache({ id: record.id, identifier: record.identifier, name: record.name }));
                }
              },
            }),
          },
          {
            title: 'Name',
            dataIndex: 'name',
            align: 'center',
            width: '10%',

            render: (txt: string) => <div className="!text-left px-[12px]">{renderCheckEmpty(txt)}</div>,
          },
          ...currentItem.children,
        ];
        return [
          {
            ...currentItem,
            children: updatedChildren,
          },
          ...total,
        ];
      }
      if (currentItem && currentItem.key !== supplierCurrent) return [...total, currentItem];
      return [...total];
    }, []);
  }, [
    listSupplierName,
    check.privia,
    check.tourvis,
    check.kyte,
    linkHotelByKeyword,
    isLoggedIn,
    dispatch,
    supplierCurrent,
  ]);

  const mergeSupplierDataAndSubSupplierData = (
    supplierData: any[],
    subSupplierData: any[],
    currentSupplierId: number
  ) => {
    const group: any = {};
    if (supplierData) {
      const supplierDataOfOthers: any[] = [];
      supplierData.forEach((item: any) => {
        if (item.hotelId) {
          const key = item.hotelId;
          if (item.supplierId === currentSupplierId) {
            group[key] = group[key] ?? [];
            group[key].push(item);
          } else supplierDataOfOthers.push(item);
        } else {
          const key = `${item.identifier},${item.supplierId}`;
          group[key] = group[key] ?? [];
          group[key].push(item);
        }
      });
      supplierDataOfOthers.forEach((item) => {
        const key = item.hotelId;
        if (group[key]) group[key].push(item);
      });
    }

    if (subSupplierData) {
      subSupplierData.forEach((item: any) => {
        const key = item.identifier;
        if (group[key]) group[key].push(item);
      });
    }
    return group;
  };

  const realData: IMetaDataHotel[] = useMemo(() => {
    const supplierData = _.filter(
      data.supplierData,
      (item: any) => item.hotelId || item.supplierId === supplierCurrent
    );
    const dataGroupByHotelId = mergeSupplierDataAndSubSupplierData(supplierData, data.subSupplierData, supplierCurrent);
    const rows = Object.values(dataGroupByHotelId)
      .map((row: any) =>
        row.reduce((acc: any, item: any) => {
          const { supplierId, rank, name, price, hotelId, siteId, id, identifier, link } = item;
          const key = (hotelId + name).toString();
          const supplierName = getSupplierName(item.supplierId);
          if (supplierId === supplierCurrent && !siteId) {
            return {
              ...acc,
              key,
              id,
              supplierId,
              identifier,
              hotelId,
              link,
              name,
              [`rank${supplierName}`]: rank,
              [`price${supplierName}`]: price,
            };
          }
          if (INTERNAL_SUPPLIERS_IDS.includes(supplierId) && siteId) {
            const subSupplierName = getSubSupplierName(siteId);
            const subPrices = {
              [`price${subSupplierName}${supplierName}`]: price,
            };

            return {
              ...acc,
              ...subPrices,
            };
          }
          return {
            ...acc,
            [`rank${supplierName}`]: rank,
            [`price${supplierName}`]: price,
          };
        }, [])
      )
      .sort((a: any, b: any) => {
        if (supplierCurrent === SUPPLIERS.privia.id && isLoggedIn) {
          return a[`ranklogin${getSupplierName(supplierCurrent)}`] - b[`ranklogin${getSupplierName(supplierCurrent)}`];
        }
        return a[`rank${getSupplierName(supplierCurrent)}`] - b[`rank${getSupplierName(supplierCurrent)}`];
      })
      .slice(0, MAX_RANK_WITH_DETAIL_PRICE);
    return rows as IMetaDataHotel[];
  }, [data, supplierCurrent, isLoggedIn]);

  useEffect(() => {
    const target = convertDayType(selectedDay.slice(1), parseInt(selectedDay.slice(0, 1), 10));
    setTargetCheckin(target);
  }, [selectedDay]);

  useEffect(() => {
    if (keywords.length) {
      setKeywordId((keywords[0] as any).id);
    }
  }, [keywords]);
  useEffect(() => {
    dispatch(actionGetKeyword());
  }, [dispatch]);
  useEffect(() => {
    dispatch(actionHotelResult({ keywordId, checkIn: targetCheckin }));
  }, [targetCheckin, dispatch, keywordId]);
  useEffect(() => {
    if (selectedHotelId) {
      setIsClickNoneHotelID(false);
      dispatch(actionGetHotelIsMapped({ hotelId: selectedHotelId }));
      dispatch(actionSetHotelIDIsMapped(0));
    }
  }, [dispatch, selectedHotelId]);
  useEffect(() => {
    if (check.privia) {
      setDataSource(getPriceAndDiff('privia', realData));
    } else if (check.tourvis) {
      setDataSource(getPriceAndDiff('tourvis', realData));
    } else if (check.kyte) {
      setDataSource(getPriceAndDiff('kyte', realData));
    } else setDataSource(realData);
  }, [check, realData]);
  useEffect(() => {
    handleSelectSupplierName(listSupplierName.filter((item) => item.show)[0]?.id);
  }, [listSupplierName]);

  return (
    <WrapperLayoutContent>
      <div className="flex flex-col h-full ">
        <div className="flex flex-row justify-between items-center h-[60px]">
          <div className="flex flex-row gap-2">
            <Select
              className="w-[180px] relative custom-select h-full left-0"
              size="large"
              value={keywordId}
              onChange={handleChange}
              allowClear
              showSearch
              options={keywords.map((kw: any) => ({
                label: kw.keyword,
                value: kw.id,
              }))}
              placeholder="Choose keyword"
            />
            <Select
              size="large"
              className="w-[150px] custom-select left-0"
              options={weekOptions}
              placeholder="Choose keyword"
              defaultValue={selectedDay}
              onChange={handleChangeDayType}
            />
          </div>
          <div className="p-1">
            <Segmented
              className="custom-segmented "
              options={listSupplierName.map((item) => {
                const { id, name, show } = item;
                return {
                  label: show && (
                    <div
                      className="px-[8px] py-[4px] w-[80px] uppercase"
                      onClick={() => {
                        handleSelectSupplierName(id);
                      }}
                      aria-hidden="true"
                    >
                      {name}
                    </div>
                  ),
                  value: name,
                };
              })}
            />
            <EllipsisOutlined rotate={90} className=" button-icon" onClick={() => setShowGrid(!showGrid)} />
          </div>
          <Popconfirm
            title="Confirm? (Current data will be deleted)"
            onConfirm={onReloadData}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <Button className="h-[40px]" type="default" icon={<ReloadOutlined />}>
              데이터 다시 수집(최대 3분소요)
            </Button>
          </Popconfirm>
        </div>
        <div className="h-[50px] flex justify-between mb-0.5">
          <div className="emptyclass w-[330px]" />
          <div className="ml-1 ">
            <SupplierModeGroup
              showGrid={showGrid}
              listSupplierName={listSupplierName}
              setListSupplierName={setListSupplierName}
              check={check}
              setCheck={setCheck}
            />
          </div>
          <div className="w-[230px]">
            <Button
              className="border-1 border-gray-500 float-right"
              onClick={() => {
                exportExcel(realData);
              }}
            >
              Export Data
            </Button>
          </div>
        </div>
        <div className="w-full overflow-hidden grow">
          <Table
            id="dataHotelResult"
            bordered
            className="custom-table-antd table-hotel-result h-[100%] "
            rowKey="key"
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            scroll={{ y: 'auto' }}
            rowClassName={(record: IMetaDataHotel) => (record.name === data.name ? 'ant-table-row-selected' : '')}
          />
        </div>
      </div>
      <Modal
        open={isOpenModal}
        onOk={handleCloseModal}
        onCancel={handleCloseModal}
        className="!w-[1000px]"
        footer={null}
        closable={false}
      >
        <Table
          title={() => <div className="font-bold">Hotel Details</div>}
          columns={!isClickNoneHotelID ? selectedHotelColumns : selectedNoneHotelID}
          rowKey="id"
          dataSource={!isClickNoneHotelID ? selectedHotelDetail : noMapHotelDetail}
          className="custom-table-antd mb-4"
          pagination={false}
          bordered
        />
        {!isClickNoneHotelID ? (
          <Table
            title={() => <div className="font-bold">Hotel Selections</div>}
            columns={modalColumns}
            rowKey="id"
            dataSource={modalData.data.slice(0, 10)}
            pagination={false}
            className="custom-table-antd"
            bordered
          />
        ) : (
          <TableMapping hotelName="" />
        )}
      </Modal>
    </WrapperLayoutContent>
  );
}

export default HotelResult;
